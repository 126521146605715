.profile-dropdown {
  @apply flex flex-col my-2 items-start text-black;
  @apply cursor-pointer;
  height: 44px;
  width: 16rem;
  z-index: 100;
  justify-self: end;
  align-self: start;
}

.profile-dropdown-header {
  height: 2.75rem;
  background: white;
  border-radius: 0.375rem;
}

.profile-dropdown-body {
  @apply flex flex-col space-y-2 justify-between w-full;
  @apply rounded-md shadow bg-gray-50;
}

.profile-dropdown-content {
  padding: 0.5rem;
}
