.mobile-navbar {
  border-top: 4px solid var(--primary);
  position: relative;
  display: flex;
  height: 60px;
  width: 100%;
  background: white;
  stroke: var(--color-gray-500);
  z-index: 98;
}

.mobile-navbar-contents {
  height: 60px;
}

.full-navbar {
  border-top: 4px solid var(--primary);
  z-index: 98;
  position: relative;
  padding-right: 0.5rem;

  display: grid;
  /*gap: 1rem;*/
  grid-template-columns: 80px 256px 1fr 256px;
}

.full-navbar.no-spacer {
  grid-template-columns: 80px 1fr 256px;
}

.mobile-searchbar-container {
  height: 60px;
  width: 100%;
  padding: 0.5rem;
}

.full-searchbar-container {
  padding: 0.5rem;
  height: 60px;
}

.navbar-icon-container {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: none;
}
