#install-instructions {
  ul {
    list-style: inside;
  }

  h1,
  h2,
  h3 {
    margin: 1em 0;
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  p {
    margin: 1em 0;
  }

  a {
    color: #1c75bc;
  }

  b {
    color: #1c75bc;
  }

  .row-then-col {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .instructions {
    margin-top: 8rem;
    min-height: 626px;

    a {
      text-decoration: underline;
    }

    h3 {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
      margin: 1rem -30vw 3rem -30vw;
      background-color: #eee;
      box-shadow: 0 6px 4px -4px #0004;
    }
  }
}
