@import 'src/features/auth/Join/Join';

.register {
  .flipper {
  }

  .boolean-input {
    margin-top: 1rem;
  }
}
