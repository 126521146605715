.search {
  @apply flex flex-col items-start;
  width: 100%;
  margin: 0.5rem auto;
  z-index: 98;
  position: relative;
}

.search.full {
  position: fixed;
  inset: 0;
  padding-top: 2rem;
  margin: 0;
  background-color: var(--color-gray-50);
}

.search.partial {
  height: 44px;
}

.search.closed {
  height: 44px;
}

.search.closed .search-body {
  cursor: pointer;
}

::placeholder {
  @apply text-opacity-50 text-gray-500;
}

.search-body {
  @apply w-full;

  padding: 0 0.5rem;
  position: relative;
}

.search-body.with-shadow {
  border-radius: 0.375rem;
  max-width: min(calc(100vw - 80px - 256px - 1rem), 32rem);
  background: white;
  @apply shadow lg:mx-auto;
}

.search-header {
  @apply flex justify-center items-center w-full space-x-4;
  height: 44px;
}

#search-input {
  width: 100%;
}

#waiting-for-query {
  overflow: hidden;
}

.search-results {
  @apply flex flex-col w-full pt-2 pb-4 px-4 bg-gray-50 relative shadow-inner;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.search-details {
  opacity: 0.3;
}
