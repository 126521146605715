.drawer {
  @apply flex flex-col;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  z-index: 100;
  background-color: var(--off-white);
  box-shadow: -100px 0px 0px 100px var(--off-white);
}

.drawer-header {
  @apply flex flex-col justify-end relative flex-shrink-0;
  width: 300px;
  height: 160px;
  overflow: hidden;
}

.drawer-body {
  @apply flex flex-col space-y-2 mt-4 px-4;
}

.drawer-header .drawer-profile-header {
  @apply z-50 relative mb-0 px-4 text-base;
  height: 80px;
  background: linear-gradient(45deg, #ffff, #fff6 75%, #fff0);
}

.scrim {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: -100%;
  z-index: 99;
}
