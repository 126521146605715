@import 'src/mixins';

.pane-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: -1;
  background: white;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  position: relative;

  @include media('>xs') {
    background: var(--color-gray-50);
  }

  .pane {
    z-index: 1;
    margin: 4rem auto 4rem auto;
    max-width: 28rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media('>xs') {
      padding: 0 1rem;
      min-height: 28rem;
    }

    .logo-container {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding: 0 1rem;
      flex-grow: 0;

      img {
        margin-left: auto;
        margin-right: auto;
        height: 5rem;
        width: auto;
      }
    }

    .pane-body {
      background: white;
      padding: 2rem 1rem;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 0;
      flex-direction: row;
      margin-top: 2rem;

      h2 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        text-align: center;
      }

      h4 {
        margin-bottom: 1rem;
        font-size: 1rem;
        color: var(--color-gray-500);
        text-align: center;
      }

      .khh-button {
        margin-top: 2rem;
      }

      @include media('>xs') {
        @include shadow;
        border-radius: 0.375rem;
        padding: 4rem 2rem 1rem 2rem;
      }
    }
  }
}
