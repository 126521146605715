@import 'src/features/auth/Join/Join';

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

  h2 {
    animation: welcome 1s forwards;
  }

  h4 {
    opacity: 0;
    animation: welcome 1s 500ms forwards;
  }
}

@keyframes welcome {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
