.mobile-bundles-list,
.mobile-things-list {
  height: calc(100%);
  width: 100vw;
  position: relative;
}

.mobile-things-list-header {
  position: sticky;
  top: 0;
  display: flex;
  height: 3rem;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
  border-bottom: solid 1px #ddd;
  padding-left: 0.5rem;
  align-items: center;
  background-color: white;
}
